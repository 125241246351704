import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'rebass';
import { useResponsiveValue } from '@theme-ui/match-media';

import image from 'assets/logo.svg';

const Logo = ({ ...props }) => {
  const breakpoint = useResponsiveValue([true, true, false]);

  const styles = {
    marginRight: breakpoint ? 'initial' : 'auto',
    img: {
      width: '180px',
    },
    span: {
      float: 'left',
      textIndent: '-9999px',
    },
  };
  return (
    <Link to="/" style={styles}>
      <span style={styles.span}>Logo</span>
      <Image src={image} alt="Logo" style={styles.img} {...props} />
    </Link>
  );
};

export default Logo;
