import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import tick from 'assets/tick.svg';
import { theme, refactorThese } from 'consts';

const propTypes = {
  children: PropTypes.string.isRequired
};

const Tick = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px 0;
  color: ${theme.colors.black}
  font-weight: ${refactorThese.bold}
  white-space: nowrap
`;

const TickIcon = styled.span`
  background: url(${tick});
  min-width: 25px;
  min-height: 25px
  margin-right: 10px;
`;

const CheckList = ({ children }) => {
  return (
    <Tick>
      <TickIcon /> {children}
    </Tick>
  );
};

CheckList.propTypes = propTypes;

export default CheckList;
