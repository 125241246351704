export default [
  {
    heading: 'Analyze All User Tweets',
    text: 'Analyze the full tweets of any public Twitter account. Get important metrics like engagement rates and type of engagement. ',
    iconName: 'tweetstorm',
  },
  {
    heading: 'New & Advanced Data Fields',
    text: 'Get access to new demographic insights like geography, gender, age, occupations and more.',
    iconName: 'report',
  },
  {
    heading: 'PDF and CSV reports',
    text: 'Easily analyze and view data in reports with charts and insights easily exported in PDF, PNG or CSV format.',
    iconName: 'download',
  },
  {
    heading: 'Receive the report in minutes',
    text: 'After the successful payment, your report will be ready in minutes.',
    iconName: 'time',
  },
];
