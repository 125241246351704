import React from 'react';
import styled from 'styled-components';

import { Inner } from 'components';
import { theme } from 'consts';

const Wrapper = styled.section`
  text-align: left;
  padding-bottom: 100px;
  line-height: 1.5;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.colors.blue};
  }

  p {
    color: ${theme.colors.grey};
  }

  ul {
    color: ${theme.colors.grey};
  }

  a {
    color: ${theme.colors.blue};
    text-decoration: none;
  }
`;

const LegalWrapper = ({ children }) => {
  return (
    <Inner display="block">
      <Wrapper>{children}</Wrapper>
    </Inner>
  );
};

export default LegalWrapper;
