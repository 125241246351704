import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';

import followers from 'assets/followers.svg';
import leads from 'assets/leads.svg';
import automatically from 'assets/automatically.svg';
import excel from 'assets/excel.svg';
import account from 'assets/account.svg';
import exportFollowers from 'assets/export-followers.svg';
import report from 'assets/report.svg';
import support from 'assets/support.svg';
import chatIcon from 'assets/chat.svg';
import databaseIcon from 'assets/database.svg';
import downloadIcon from 'assets/download.svg';
import timeIcon from 'assets/time.svg';
import trendsIcon from 'assets/trends.svg';
import caseStudy1 from 'assets/case-study-icon-1.svg';
import caseStudy2 from 'assets/case-study-icon-2.svg';
import caseStudy3 from 'assets/case-study-icon-3.svg';
import caseStudy4 from 'assets/case-study-icon-4.svg';
import caseStudy5 from 'assets/case-study-icon-5.svg';
import caseStudy6 from 'assets/case-study-icon-6.svg';
import caseStudy7 from 'assets/case-study-icon-7.svg';
import useCase1 from 'assets/use-cases-analysis-in-depth.svg';
import useCase2 from 'assets/use-cases-audience.svg';
import useCase3 from 'assets/use-cases-business-discovery.svg';
import useCase4 from 'assets/use-cases-content-sourcing.svg';
import useCase5 from 'assets/use-cases-customers.svg';
import useCase6 from 'assets/use-cases-engaging-media.svg';
import useCase7 from 'assets/use-cases-journalist.svg';
import useCase8 from 'assets/use-cases-manage-reputation.svg';
import useCase9 from 'assets/use-cases-popular.svg';
import useCase10 from 'assets/use-cases-relevant-users.svg';
import useCase11 from 'assets/use-cases-understand-competitors.svg';
import twitterIcon from 'assets/twitter-logo-white.svg';
import twitterBlueIcon from 'assets/twitter-logo.svg';
import instagramIcon from 'assets/instagram-logo.svg';
import tweetstormIcon from 'assets/tweetstorm.svg';
import mediaIcon from 'assets/media.svg';
import onlineMediaIcon from 'assets/online-media.svg';
import approvedIcon from 'assets/approved.svg';
import contactsIcon from 'assets/contacts.svg';
import locationIcon from 'assets/location.svg';
import ratioIcon from 'assets/ratio.svg';

const propTypes = {
  iconName: PropTypes.string.isRequired,
  height: PropTypes.number,
  maxWidth: PropTypes.bool
};

const defaultProps = {
  height: 120,
  maxWidth: false
};

const Image = styled.img`
  height: ${props => props.height}px;

  ${ifProp(
    'maxWidth',
    css`
      width: 100%;
    `
  )}
`;

const Icon = ({ iconName, height, maxWidth, ...props }) => {
  let url;
  let altText;

  switch (iconName) {
    case 'followers':
      url = followers;
      altText = 'Follower Analysis';
      break;
    case 'leads':
      url = leads;
      altText = 'Lead Generation';
      break;
    case 'automatically':
      url = automatically;
      altText = 'Automatic Reports';
      break;
    case 'excel':
      url = excel;
      altText = 'Export to Excel or CSV';
      break;
    case 'account':
      url = account;
      altText = 'Export any account';
      break;
    case 'exportFollowers':
      url = exportFollowers;
      altText = 'Export any number of followers';
      break;
    case 'report':
      url = report;
      altText = 'Get the report in 15 minutes';
      break;
    case 'support':
      url = support;
      altText = '24/7 Support';
      break;
    case 'caseStudy1':
      url = caseStudy1;
      altText = 'Social Media Marketing';
      break;
    case 'caseStudy2':
      url = caseStudy2;
      altText = 'Link Building';
      break;
    case 'caseStudy3':
      url = caseStudy3;
      altText = 'Freelance Marketing';
      break;
    case 'caseStudy4':
      url = caseStudy4;
      altText = 'Business Development';
      break;
    case 'caseStudy5':
      url = caseStudy5;
      altText = 'Freelance Marketing';
      break;
    case 'caseStudy6':
      url = caseStudy6;
      altText = 'Business Development';
      break;
    case 'caseStudy7':
      url = caseStudy7;
      altText = 'Backup your Twitter Followers';
      break;
    case 'useCase1':
      url = useCase1;
      altText = 'In Depth Analysis';
      break;
    case 'useCase2':
      url = useCase2;
      altText = 'Audience';
      break;
    case 'useCase3':
      url = useCase3;
      altText = 'Business Discovery';
      break;
    case 'useCase4':
      url = useCase4;
      altText = 'Content Sourcing';
      break;
    case 'useCase5':
      url = useCase5;
      altText = 'Customers';
      break;
    case 'useCase6':
      url = useCase6;
      altText = 'Engaging Media';
      break;
    case 'useCase7':
      url = useCase7;
      altText = 'Journalist';
      break;
    case 'useCase8':
      url = useCase8;
      altText = 'Manage Reputation';
      break;
    case 'useCase9':
      url = useCase9;
      altText = 'Popular';
      break;
    case 'useCase10':
      url = useCase10;
      altText = 'Relevant Users';
      break;
    case 'useCase11':
      url = useCase11;
      altText = 'Understand Competitors';
      break;
    case 'Twitter':
      url = twitterIcon;
      altText = 'Twitter Icon';
      break;
    case 'TwitterBlue':
      url = twitterBlueIcon;
      altText = 'Twitter Icon';
      break;
    case 'Instagram':
      url = instagramIcon;
      altText = 'Twitter Icon';
      break;
    case 'chat':
      url = chatIcon;
      altText = '24/7 Support';
      break;
    case 'database':
      url = databaseIcon;
      altText = 'Largest Database';
      break;
    case 'download':
      url = downloadIcon;
      altText = 'Download results in PDF or CSV';
      break;
    case 'tweetstorm':
      url = tweetstormIcon;
      altText = 'Export latest tweets';
      break;
    case 'media':
      url = mediaIcon;
      altText = 'Separate Media Files';
      break;
    case 'onlineMedia':
      url = onlineMediaIcon;
      altText = 'Online Media';
      break;
    case 'time':
      url = timeIcon;
      altText = 'Time';
      break;
    case 'trends':
      url = trendsIcon;
      altText = 'Trends';
      break;
    case 'approved':
      url = approvedIcon;
      altText = 'Approved';
      break;
    case 'contacts':
      url = contactsIcon;
      altText = 'Contacts';
      break;
    case 'location':
      url = locationIcon;
      altText = 'Location';
      break;
    case 'ratio':
      url = ratioIcon;
      altText = 'Ratio';
      break;
    default:
      break;
  }

  return (
    <Image
      src={url}
      alt={altText}
      maxWidth={maxWidth}
      {...{ height }}
      {...props}
    />
  );
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
