import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  children: PropTypes.node.isRequired,
  display: PropTypes.string,
  justifyContent: PropTypes.string,
  textAlign: PropTypes.string,
};

const defaultProps = {
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'center',
};

const InnerContainer = styled.section`
  position: relative;
  display: ${(props) => props.display};
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '1170px')};
  margin: 0 auto;
  padding: 32px 32px 0;
  text-align: ${(props) => props.textAlign};
  align-items: center;
  box-sizing: content-box;
  padding-top: ${(props) => (props.pt ? `${props.pt}px` : '')};
`;

const Inner = ({
  children,
  display,
  justifyContent,
  textAlign,
  id,
  ...props
}) => {
  return (
    <InnerContainer
      id={id}
      {...{ display, justifyContent, textAlign }}
      {...props}
    >
      {children}
    </InnerContainer>
  );
};

Inner.propTypes = propTypes;
Inner.defaultProps = defaultProps;

export default Inner;
