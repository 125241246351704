export default [
  {
    title: 'Tweet ID',
    text: 'Unique ID of the Tweet.',
  },
  {
    title: 'Tweet URL',
    text: 'URL of the Tweet.',
  },
  {
    title: 'Tweet Posted Time',
    text: 'The UTC time when this Tweet was posted.',
  },
  {
    title: 'Tweet Type',
    text: 'Identifies if the tweet is a reply, quote, retweet or regular tweet.',
  },
  {
    title: 'Tweet Text',
    text: 'Tweet content with up to 280 characters (max).',
  },
  {
    title: 'Tweet Text Length',
    text: 'Total number of symbols in the text.',
  },
  {
    title: 'Client',
    text: 'The device or application through which the tweet was posted.',
  },
  {
    title: 'Client URL',
    text: 'The URL of the application that the tweeet was posted from.',
  },
  {
    title: 'Total Views',
    text: 'Number of times the Tweet was viewed.',
  },
  {
    title: 'Total Retweets',
    text: 'Number of retweets on the Tweet.',
  },
  {
    title: 'Total Likes',
    text: 'Number of likes on the Tweet.',
  },
  {
    title: 'Total Replies',
    text: 'Number of replies on the Tweet.',
  },
  {
    title: 'Total Quotes',
    text: 'Number of quotes on the Tweet.',
  },
  {
    title: 'Total Bookmarks',
    text: 'Number of times the Tweet was bookmarked.',
  },
  {
    title: 'Language',
    text: 'Machine-detected language of the tweet.',
  },
  {
    title: 'Sensitive Content',
    text: 'Identifies if the URL or media contained in the tweet is sensitive content.',
  },
  {
    title: 'Replying To Username',
    text: 'If the tweet is a reply, it will contain the name of the original Tweet author.',
  },
  {
    title: 'Total Mentions',
    text: 'Number of user mentions in a tweet.',
  },
  {
    title: 'Total Hashtags',
    text: 'Number of hashtags used in a tweet.',
  },
  {
    title: 'Total URLs',
    text: 'Number of URLs used in a tweet.',
  },
  {
    title: 'Total Media',
    text: 'Number of photos, animated gifs and videos used in a tweet.',
  },
  {
    title: 'Total Emojis',
    text: 'Number of emojis used in a tweet.',
  },
  {
    title: 'Total Symbols',
    text: 'Number of cashtags (stock market tickers such as $EUR or $BTC) used in a tweet.',
  },
  {
    title: 'Total polls',
    text: 'Number of polls used in a tweet.',
  },
  {
    title: 'URL',
    text: 'The URL used in a tweet.',
  },
  {
    title: 'Media Type',
    text: 'Identifies the media type used in the tweet. It can be a photo, video or animated gif.',
  },
  {
    title: 'Media URL',
    text: 'URL of the media used in a tweet.',
  },
  {
    title: 'Media Alt Text',
    text: 'Alt text of the media used in a tweet.',
  },
  {
    title: 'Tweet Truncated',
    text: 'Identifies if the tweet was shortened due to retweet exeeding the original tweet text for example.',
  },
  {
    title: 'Original Author Twitter ID',
    text: 'Unique Twitter identifier of the user who posted the original tweet.',
  },
  {
    title: 'Original Author Full name',
    text: 'Full name of the user who posted the original tweet.',
  },
  {
    title: 'Original Author Screen name',
    text: 'Username of the user who posted the original tweet.',
  },
  {
    title: 'Original Author Location',
    text: 'The user-defined location of the user who posted the original tweet.',
  },
  {
    title: 'Original Author Description (Bio)',
    text: 'The user-defined description of the user who posted the original tweet.',
  },
  {
    title: 'Original Author Website',
    text: 'URL associated with the Twitter original author profile.',
  },
  {
    title: 'Original Author Professional Type',
    text: 'The user-defined professional type of the user who posted the original tweet.',
  },
  {
    title: 'Original Author Professional Category',
    text: 'The user-defined professional category of the user who posted the original tweet.',
  },
  {
    title: 'Original Author Total Followers',
    text: 'The number of followers that the original author currently has.',
  },
  {
    title: 'Original Author Total Following',
    text: 'The number of followings that the original author currently has.',
  },
  {
    title: 'Original Author Total Public Lists',
    text: 'The number of public lists the original author is a member of.',
  },
  {
    title: 'Original Author Total Likes',
    text: "The number of Tweets the original author liked in the account's lifetime.",
  },
  {
    title: 'Original Author Total Tweets',
    text: "The number of Tweets (including retweets) the original author has posted in the account's lifetime.",
  },
  {
    title: 'Original Author Is Twitter Blue Verified',
    text: 'The indication that the original author has verified his identity through Twitter Blue.',
  },
  {
    title: 'Original Author Is Legacy Verified',
    text: 'The indication that the original author has verified his identity through the legacy verification process.',
  },
  {
    title: 'Original Author Has NFT Avatar',
    text: 'The indication that the author has an NFT avatar.',
  },
  {
    title: 'Original Author Protected Tweets',
    text: 'The indication that the original author has chosen to show his tweets only to his followers.',
  },
  {
    title: 'Original Author Created At (UTC)',
    text: 'Account creation date in the UTC timezone.',
  },
  {
    title: 'Original Author Email*',
    text: 'User-defined public email address. It is automatically extracted and validated if present.',
  },
  {
    title: 'Original Author Phone*',
    text: 'User-defined public international phone number. It is automatically extracted and validated if present.',
  },
  {
    title: 'Separate media files',
    text: 'Separate CSV and Excel files for all images, gifs and videos.',
  },
  {
    title: 'Separate url files',
    text: 'Separate CSV and Excel files for all urls in the tweets.',
  },
];
