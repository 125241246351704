import React from 'react';
import { Flex, Box } from 'rebass';
import { NavLink, withRouter } from 'react-router-dom';
import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share';

import { Logo } from 'components';
import { theme } from 'consts';
import {
  FooterWrapper,
  FooterInner,
  ColumnHeader,
  SocialLinksWrapper,
} from './Styles';

const Footer = ({ history }) => {
  return (
    <>
      {!history.location.pathname.includes('callback') ? (
        <FooterWrapper>
          <FooterInner>
            <Flex
              justifyContent="space-evenly"
              flexDirection={['column', 'column', 'row']}
              maxWidth={['100%', '100%', '1170px']}
              margin="40px auto 0"
              sx={{
                textAlign: ['center', 'center', 'left'],
              }}
            >
              {/* <Box mb="4">
                <ColumnHeader
                  fontSize={[3, 3, 4]}
                  lineColor={theme.colors.pink}
                >
                  <span>We work with</span>
                </ColumnHeader>
                <Flex flexDirection="column" my="4">
                  <NavLink to="/#">
                    <span>Marketers</span>
                  </NavLink>
                  <NavLink to="/#">
                    <span>Researchers</span>
                  </NavLink>
                  <NavLink to="/#">
                    <span>Universities</span>
                  </NavLink>
                  <NavLink to="/#">
                    <span>Enterprise</span>
                  </NavLink>
                </Flex>
              </Box> */}
              <Box mb="4">
                <ColumnHeader
                  fontSize={[3, 3, 4]}
                  lineColor={theme.colors.teal}
                >
                  <span>Products</span>
                </ColumnHeader>
                <Flex flexDirection="column" my="4">
                  <NavLink to="/download-twitter-followers">
                    <span>Download Twitter Followers and Following List</span>
                  </NavLink>
                  <NavLink to="/download-tweets">
                    <span>
                      Search and Download Historical Tweets by Hashtag or Keyword
                    </span>
                  </NavLink>
                  <NavLink to="/download-twitter-account-timeline">
                    <span>Download Twitter Account Timeline</span>
                  </NavLink>
                  <NavLink to="/twitter-influencers">
                    <span>Find Twitter Influencers</span>
                  </NavLink>
                </Flex>
              </Box>
              <Box mb="4">
                <ColumnHeader
                  fontSize={[3, 3, 4]}
                  lineColor={theme.colors.yellow}
                >
                  <span>Company</span>
                </ColumnHeader>
                <Flex flexDirection="column" my="4">
                  <NavLink to="/privacy-policy">
                    <span>Privacy Policy</span>
                  </NavLink>
                  <NavLink to="/terms-and-conditions">
                    <span>Terms & Conditions</span>
                  </NavLink>
                </Flex>
              </Box>
            </Flex>
            <Flex
              flexDirection={['column', 'column', 'row']}
              maxWidth={['100%', '100%', '1170px']}
              margin="0 auto"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Logo style={{ marginRight: 0 }} />
              <Flex alignItems="center">
                Copyright &copy; {new Date().getFullYear()} ExportData.io
              </Flex>
            </Flex>
          </FooterInner>
        </FooterWrapper>
      ) : null}
    </>
  );
};

export default withRouter(Footer);
