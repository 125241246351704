/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

const Notification = types
  .model({
    message: types.frozen({
      type: types.string,
      title: types.string,
      message: types.string,
      autoDismiss: types.boolean
    })
  })
  .actions(self => ({
    setMessage(obj) {
      self.message = !Object.keys(obj).includes('autoDismiss')
        ? { ...obj, autoDismiss: true }
        : obj;
    }
  }));

export default Notification;
