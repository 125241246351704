export default [
  {
    title: 'Find the most popular tweets',
    text: `Acquire analysis and reports with statistical data of the full Twitter account timeline.
    Find the most engaging or retweeted Tweets, and research why they received that amount of public exposure.
    Analyze what are the most effective tweeting times during the day, week or month to reach your audience.`,
    iconName: 'tweetstorm',
    reverse: true,
  },
  {
    title: 'Content sourcing',
    text: `Analyze what works and implement best practices and ideas for your social media and marketing strategies.
    Review and research Tweet content from the selected Twitter account. This data collection allows understanding
    key factors that drive audience engagement based on certain keywords, hashtags, tone of voice and other content information.
    Elevate your tactics and create the best approach to reach your audience.`,
    iconName: 'useCase4',
    reverse: false,
  },
  {
    title: 'In-depth Tweet Analysis',
    text: `Check and keep up to date with the most occurring keywords and hashtags that selected Twitter account uses.
    Calculate the audience engagement rate for specific content and topics. Extensive Tweet data analysis provides advanced
    knowledge of key trends surrounding audience involvement.`,
    iconName: 'useCase1',
    reverse: false,
  },
];
