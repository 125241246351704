export default [
  {
    title: 'Unique Twitter ID',
    text: 'Unique identifier of the user on the Twitter platform.',
  },
  {
    title: 'Full Name',
    text: 'The full name of the user.',
  },
  {
    title: 'Screen Name',
    text: 'Unique user account handle.',
  },
  {
    title: 'Profile URL',
    text: 'URL of the user profile.',
  },
  {
    title: 'Location',
    text: 'The user-defined location for this account’s profile.',
  },
  {
    title: 'Description (Bio)',
    text: 'The user-defined description of their account.',
  },
  {
    title: 'Website',
    text: 'URL associated with the Twitter user profile.',
  },
  {
    title: 'Total Followers',
    text: 'The number of followers that the account currently has.',
  },
  {
    title: 'Total Following',
    text: 'The number of followings that the account currently has.',
  },
  {
    title: 'Total Likes',
    text: "The number of Tweets the user liked in the account's lifetime.",
  },
  {
    title: 'Total Tweets',
    text: "The number of Tweets (including retweets) the user has posted in the account's lifetime.",
  },
  {
    title: 'Total Public Lists',
    text: 'The number of public lists the user is a member of.',
  },
  {
    title: 'Created At',
    text: 'Account creation date in the UTC timezone.',
  },
  {
    title: 'Last Tweet At',
    text: 'Most recent user tweet or retweet activity date in the UTC timezone.',
  },
  {
    title: 'Verification Status',
    text: 'The indication that the user has verified his identity through Twitter.',
  },
  {
    title: 'Tweets Protection Status',
    text: 'The indication that the user has chosen to show his tweets only to his followers.',
  },
  {
    title: 'Email*',
    text: 'User-defined public email address. It is automatically extracted and validated.',
  },
  {
    title: 'Phone Number*',
    text: 'User-defined public international phone number. It is automatically extracted and validated.',
  },
];
