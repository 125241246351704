export default [
  {
    title: 'Find Influencers',
    text: `Influencers these days can drive your Brand sales through the roof! Finding them just became a whole lot
      easier using our influencer identification tools. If they promote your competitors that means
      they are right in your niche and interested in the type of product or service you are trying to promote.</p>
      <p>All that’s left to do is order followers and followings report and find out who are your
      competitors brand ambassadors!`,
    iconName: 'caseStudy2',
    reverse: false
  },
  {
    title: 'Build and optimize a Tailored Audience for Twitter Ads',
    text: `Running Twitter Ads with valuable segment information will drastically reduce your advertising budget.
      Target those who are potentially interested, leave out the rest. And this is one of the most
      effective ways to get the right target audience attention - analyze your competitor followers
      and following list - this is simply smart business.`,
    iconName: 'caseStudy3',
    reverse: true
  },
  {
    title: 'Research and Education',
    text: `Many researchers and students are constantly looking for valuable information to support or base their
    thesis on. Having analysis of twitter account followers and following is very convenient
    because all the information is right there at your fingertips.
    Whether you are looking to analyze political impact or predict followers or followings growth - look no further.`,
    iconName: 'caseStudy5',
    reverse: true
  },
  {
    title: 'Twitter Account Analysis',
    text: `There are lots of valuable analytical insights you will gain by using our followers and following analysis.
    Here are only a few to name - Find geographic composition, percentage of active followers, gender, occupation, the right target audience,
    influencers, and many more...`,
    iconName: 'caseStudy6',
    reverse: false
  }
];
