import styled from 'styled-components';

const CheckBoxWrapper = styled.div`
  display: flex;
  margin: 20px 10px 30px;

  label {
    font-size: 22px;
    cursor: pointer;
    text-transform: capitalize;
  }
  input {
    position: relative;
    top: -4px;
    margin: 10px;
    transform: scale(1.6);
    cursor: pointer;
  }
`;

export default CheckBoxWrapper;
