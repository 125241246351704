import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import WebFont from 'webfontloader';

WebFont.load({
  classes: false,
  events: false,
  google: {
    families: ['Open Sans:400', 'Fira Sans:500,700', 'Archivo Black:400'],
  },
});

ReactDOM.render(<App />, document.getElementById('root'));
