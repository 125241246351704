import React from 'react';
import { Box, Card, Heading, Text } from 'rebass';

import { Icon } from 'components';

const SingleFeature = ({ feature }) => {
  return (
    <Card sx={{ boxShadow: 'largeArea' }}>
      <Box sx={{ pb: 3 }}>
        <Icon iconName={feature.iconName} />
      </Box>
      <Heading as="h3" fontSize="3">
        {feature.heading}
      </Heading>
      <Text as="p" mt="3">
        {feature.text}
      </Text>
    </Card>
  );
};

export default SingleFeature;
