import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Heading, Text } from 'rebass';
import { useColorMode } from 'theme-ui';

const propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  reverse: PropTypes.bool
};

const defaultProps = {
  reverse: false
};

const Card = ({ icon, title, children, reverse, useCases }) => {
  const [theme] = useColorMode();

  return (
    <Flex
      alignItems="center"
      flexDirection={['column', 'column', reverse ? 'row-reverse' : 'row']}
      mb={[4, 5, 6]}
    >
      <Box
        width={[1, 1, useCases ? '50%' : '30%']}
        sx={{
          img: {
            height: useCases ? ['120px', '180px', '280px'] : 'auto'
          }
        }}
      >
        {icon}
      </Box>

      <Box width={[1, 1, useCases ? '50%' : '70%']}>
        <Heading
          as="h3"
          sx={{
            my: 3,
            fontSize: 4,
            color: theme === 'dark' ? 'teal' : 'text',
            textAlign: 'center'
          }}
        >
          {title}
        </Heading>
        <Text
          sx={{
            px: [0, 4, 4],
            lineHeight: 'body'
          }}
        >
          {children}
        </Text>
      </Box>
    </Flex>
  );
};

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
