import styled from 'styled-components';
import { Text } from 'rebass';

import { theme } from 'consts';

export const FooterWrapper = styled.footer`
  background: ${theme.colors.blue};
  padding: 10px 10px 30px;
  display: flex;
  align-items: center;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTI0IiBoZWlnaHQ9IjUwMyIgdmlld0JveD0iMCAwIDkyNCA1MDMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01OC41NTA0IDk4My4xMjlDLTIxNS42NzYgOTc3LjM3MiAtMzUxLjg2OSA4MjUuNjkgLTQ4Ny4xNzUgNzA5LjAwM0MtNTk1LjI1OCA2MTUuNzkyIC02NjIuNzc5IDUxMi43ODYgLTYwNi45NzQgNDA5LjE4M0MtNTUxLjI1NSAzMDUuNzM4IC00MDYuNjA5IDIxNC4xMjMgLTIwMC45MDEgMTc3LjUyN0MtOC41NDI3NyAxNDMuMzA1IDE4My44NTggMTk0LjQyMyAzNzAuNzUxIDIzNS4yMzlDNTgxLjc0MyAyODEuMzE4IDg0OC4yMDUgMzExLjQgOTEwLjIgNDIwLjE4NUM5NzUuMDUyIDUzMy45ODEgNzk4LjEwOCA2MzcuOTc3IDY1NC40MDEgNzMyLjk2N0M0OTAuNjMyIDg0MS4yMiAzMzMuODA0IDk4OC45MDcgNTguNTUwNCA5ODMuMTI5WiIgZmlsbD0iI0ZGNEQ5QyIvPgo8cGF0aCBkPSJNLTYwMi43OSA2MzguOTk4Qy03NDEuNDI3IDU3NS4wMTEgLTkxMy44MTQgNTE2LjI2MyAtOTE5Ljg0MiA0MjMuMTMyQy05MjUuNzU4IDMzMS43MzUgLTc2NS4xMjggMjY3LjY0NSAtNjMwLjk1NSAyMDMuODlDLTQ4NC45ODEgMTM0LjUyOCAtMzQxLjcyNyAzOS4zNzE2IC0xMzkuODg3IDUyLjUyM0M2NS43NzI5IDY1LjkyMzIgMTU0Ljc4NCAxNzcuMTA1IDI2Ni45MTcgMjYyLjM5M0MzOTUuODcxIDM2MC40NzUgNTg5LjgzNSA0NTMuNzY0IDUzNy4xMjggNTY3LjQ1OEM0NzYuMjUzIDY5OC43NjkgMjY5LjY5NCA4MjguODQ4IC0zLjYzNzUzIDg0Ni4wMDJDLTI1OC44OTMgODYyLjAyMiAtNDE1LjcxNSA3MjUuMzQxIC02MDIuNzkgNjM4Ljk5OFoiIGZpbGw9IiM0NUQ4RUYiLz4KPHBhdGggZD0iTS05MS4yNzY5IDE4MS4xOTlDMTkuNzQyNiAyMDIuMTUyIDE1NS4xMjQgMjE0Ljc1OCAxNzEuNTIxIDI3NC4wMzRDMTg3LjYxMyAzMzIuMjA3IDc2LjY4MTggMzk3LjIyNiAtMTQuNjY5NiA0NTguMTM5Qy0xMTQuMDU1IDUyNC40MDkgLTIwOC4xMiA2MDYuOTQxIC0zNTkuNTA4IDYyOC4xMjFDLTUxMy43NjEgNjQ5LjcwMSAtNTk0LjAxNyA1OTAuOTY0IC02ODguMTA3IDU1Mi4zNTRDLTc5Ni4zMTEgNTA3Ljk1MiAtOTUyLjExOSA0NzYuMjA0IC05MjcuNTg2IDM5NS4wMDlDLTg5OS4yNTEgMzAxLjIzMSAtNzYyLjcwNCAxODYuODMgLTU2Mi4xNzMgMTM1LjU2M0MtMzc0LjkwMyA4Ny42ODU3IC0yNDEuMDg2IDE1Mi45MjUgLTkxLjI3NjkgMTgxLjE5OVoiIGZpbGw9IiNGRkUyNEQiLz4KPC9zdmc+Cg==');
  background-position: left bottom;
  background-repeat: no-repeat;
`;

export const FooterInner = styled.div`
  width: 100%;
  color: #fff;
  a {
    display: inline-block;
    position: relative;
    color: #fff;
    text-decoration: none;
    transition: all 0.5s;
    margin: 12px 0;
    font-weight: ${theme.fontWeights.heading};

    span {
      position: relative;
      &:after {
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        content: '';
        border-bottom: solid 1px ${theme.colors.electricGreen};
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
        transform-origin: 0 0;
      }
      &:hover {
        &:after {
          transform: scaleX(1);
        }
      }
    }
  }
`;

export const ColumnHeader = styled(Text)`
  color: ${theme.colors.purple[1]};
  font-weight: ${theme.fontWeights.heading};
  font-family: ${theme.fonts.heading};

  span {
    position: relative;
    &:after {
      content: '';
      width: 30px;
      height: 2px;
      position: absolute;
      left: 50%;
      margin-left: -15px;
      bottom: -20px;
      background: ${(props) => (props.lineColor ? props.lineColor : '#fff')};

      @media (min-width: ${theme.breakpoints[1]}) {
        left: 0;
        margin-left: 0;
      }
    }
  }
`;

export const SocialLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    border-radius: 50%;
    margin: 8px;
    position: relative;
    span {
      display: none;
    }
    &:hover {
      &:after {
        opacity: 1;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      width: 38px;
      height: 38px;
      border: 3px solid rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      opacity: 0;
      transition: all 0.5s;
    }
  }
`;
