import React from 'react';
import { Flex } from 'rebass';
import { useResponsiveValue } from '@theme-ui/match-media';

import styled from 'styled-components';
import loaderLogo from 'assets/loader-logo.svg';

const LoaderWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    background: url(${loaderLogo});
    width: 50px;
    height: 50px;
    background-size: contain;
    animation: ripple2 1s ease-out infinite;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    border: 2px solid #4f4b95;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    animation: ripple 1s ease-out infinite;
  }

  @keyframes ripple {
    from {
      width: 50px;
      height: 50px;
      opacity: 1;
    }
    to {
      width: 100px;
      height: 100px;
      opacity: 0;
    }
  }

  @keyframes ripple2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

const Loader = ({ dashboard = false }) => {
  const breakpoint = useResponsiveValue([false, false, true]);
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      height={dashboard ? '100%' : '100vh'}
      width={dashboard ? 'calc(100vw - 320px)' : '100vw'}
      sx={{
        ...(dashboard &&
          breakpoint && {
            left: 0,
            position: 'relative',
          }),
      }}
    >
      <LoaderWrapper />
    </Flex>
  );
};

export default Loader;
