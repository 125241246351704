import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Flex, Box, Heading } from 'rebass';

import { Logo, TwitterLoginButton, Inner, Nav } from 'components';
import { ReactComponent as ViralTweet } from 'assets/viral-tweet.svg';
import { ReactComponent as Selfie } from 'assets/selfie.svg';
import { ReactComponent as Followers } from 'assets/export-followers.svg';
import { ReactComponent as Tweets } from 'assets/tweetstorm.svg';
import { ReactComponent as Report } from 'assets/report.svg';
import { ReactComponent as Bio } from 'assets/profile.svg';
import headerData from 'consts/header-data';
import HeaderContainer from './Styles';

const Header = ({ history }) => {
  const [show, setShow] = useState();
  const [minimal, setMinimal] = useState(false);
  const [headerText, setHeaderText] = useState(headerData.homepage);

  /**
   * Set minimal Header for a page such as `Cookies`...
   */
  useEffect(() => {
    if (
      history.location.pathname.includes('trends') ||
      history.location.pathname.includes('cookies') ||
      history.location.pathname.includes('terms-and-conditions') ||
      history.location.pathname.includes('privacy-policy') ||
      history.location.pathname.includes('team') ||
      history.location.pathname.includes('contact-us') ||
      history.location.pathname.includes('careers')
    ) {
      setMinimal(true);
    } else {
      setMinimal(false);
    }
  }, [history.location]);

  useEffect(() => {
    setShow(true);
    if (
      /**
       * Check if `export` is not first URL segment
       * E.g: `export/keyword-hashtag` is used in Back Office so
       * we hide this header!
       */
      history.location.pathname
        .substring(0, history.location.pathname.lastIndexOf('/'))
        .includes('export') ||
      history.location.pathname.includes('/callback') ||
      history.location.pathname.includes('/account')
    ) {
      setShow(false);
    }
    switch (history.location.pathname) {
      case '/download-twitter-followers':
        setHeaderText(headerData.followersFollowings);
        break;
      case '/download-tweets':
        setHeaderText(headerData.tweets);
        break;
      case '/download-twitter-account-timeline':
        setHeaderText(headerData.timeline);
        break;
      case '/twitter-influencers':
        setHeaderText(headerData.bioSearch);
        break;
      default:
        setHeaderText(headerData.homepage);
        break;
    }
  }, [history.location]);

  return (
    <>
      {show ? (
        <>
          <HeaderContainer minimal={minimal}>
            <Inner display="block" pt="30">
              <Flex justifyContent="space-between" alignItems="center">
                <Logo />
                <Nav />
              </Flex>
              {!minimal && (
                <Flex>
                  <Flex
                    flexDirection="column"
                    justifyContent="space-between"
                    width={[1, 1, 1 / 2]}
                    height={420}
                    pt="5"
                    sx={{ textAlign: 'left' }}
                  >
                    <Heading as="span" variant="header-span">
                      {headerText.span}
                    </Heading>
                    <Heading as="h1" variant="header-h1">
                      {headerText.h1}
                    </Heading>
                    <Heading as="h2" variant="header-h2">
                        {headerText.h2}
                    </Heading>
                    <Box>
                      <TwitterLoginButton
                        dashboardRedirectURL={headerText.dashboardRedirectURL}
                      />
                    </Box>
                  </Flex>
                  <Flex
                    width={[0, 0, 1 / 2]}
                    sx={{
                      svg: {
                        top: '-90px',
                        position: 'relative',
                        ...(headerText.image === 'selfie' && { top: 0 }),
                        ...(headerText.image === 'product' && { top: 0 }),
                        ...(headerText.image === 'report' && { left: 20 }),
                      },
                    }}
                  >
                    {headerText.image === 'viral-tweet' && <ViralTweet />}
                    {headerText.image === 'selfie' && <Selfie />}
                    {headerText.image === 'product' && <Followers />}
                    {headerText.image === 'tweetstorm' && <Tweets />}
                    {headerText.image === 'report' && <Report />}
                    {headerText.image === 'bio' && <Bio />}
                  </Flex>
                </Flex>
              )}
            </Inner>
          </HeaderContainer>
        </>
      ) : null}
    </>
  );
};

export default withRouter(Header);
