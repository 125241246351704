import { createGlobalStyle } from 'styled-components';

import { theme } from 'consts';

export default createGlobalStyle`

  html {
    height: 100%;
  }

	body {
    min-height: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${theme.fonts.body};
    background: red;

    &.menu-open {
      overflow: hidden;
    }
  }

  .menu-open .fc-widget-small {
    display: none;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid ${theme.colors.grey};
    color: #6A6A6A;
    text-align: center;

    th {
      &:nth-child(2) {
        min-width: 250px;
      }
      &:nth-child(4) {
        min-width: 100px;
      }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid ${theme.colors.grey};
      border-right: 1px solid ${theme.colors.grey};

      :last-child {
        border-right: 0;
      }
    }
  }

  .innerTable {
    padding: 0;
    & > table {
      width: 100%;
      border: none;
      tr {
        &:nth-child(1) {
          td {
            border: none;
          }
        }
      }
      td {
        border-top: 1px solid ${theme.colors.grey};
      }
    }
  }

  ol {
    color: ${theme.colors.grey};
  }

  .noBullets {
    list-style-type: none;

    li {
      margin-bottom: 16px;
    }
  }

  .my-masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }

  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  .my-masonry-grid_column > div {
    margin-bottom: 30px;
  }

  .cc-window {
    opacity: 1;
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;
  }
  .cc-window.cc-invisible {
    opacity: 0;
  }
  .cc-animate.cc-revoke {
    -webkit-transition: transform 1s ease;
    -webkit-transition: -webkit-transform 1s ease;
    transition: -webkit-transform 1s ease;
    transition: transform 1s ease;
    transition: transform 1s ease, -webkit-transform 1s ease;
  }
  .cc-animate.cc-revoke.cc-top {
    -webkit-transform: translateY(-2em);
    transform: translateY(-2em);
  }
  .cc-animate.cc-revoke.cc-bottom {
    -webkit-transform: translateY(2em);
    transform: translateY(2em);
  }
  .cc-animate.cc-revoke.cc-active.cc-top {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .cc-animate.cc-revoke.cc-active.cc-bottom {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .cc-revoke:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .cc-grower {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 1s;
    transition: max-height 1s;
  }
  .cc-revoke,
  .cc-window {
    position: fixed;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Helvetica, Calibri, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    z-index: 9999;
  }
  .cc-window.cc-static {
    position: static;
  }
  .cc-window.cc-floating {
    padding: 2em;
    max-width: 24em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .cc-window.cc-banner {
    padding: 1em 1.8em;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .cc-revoke {
    padding: 0.5em;
  }
  .cc-revoke:hover {
    text-decoration: underline;
  }
  .cc-header {
    font-size: 18px;
    font-weight: 700;
  }
  .cc-btn,
  .cc-close,
  .cc-link,
  .cc-revoke {
    cursor: pointer;
  }
  .cc-link {
    opacity: 0.8;
    display: inline-block;
    padding: 0.2em;
    text-decoration: underline;
  }
  .cc-link:hover {
    opacity: 1;
  }
  .cc-link:active,
  .cc-link:visited {
    color: initial;
  }
  .cc-btn {
    display: block;
    padding: 0.4em 0.8em;
    font-size: 0.9em;
    font-weight: 700;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    white-space: nowrap;
  }
  .cc-highlight .cc-btn:first-child {
    background-color: transparent;
    border-color: transparent;
  }
  .cc-highlight .cc-btn:first-child:focus,
  .cc-highlight .cc-btn:first-child:hover {
    background-color: transparent;
    text-decoration: underline;
  }
  .cc-close {
    display: block;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    font-size: 1.6em;
    opacity: 0.9;
    line-height: 0.75;
  }
  .cc-close:focus,
  .cc-close:hover {
    opacity: 1;
  }
  .cc-revoke.cc-top {
    top: 0;
    left: 3em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
  .cc-revoke.cc-bottom {
    bottom: 0;
    left: 3em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  .cc-revoke.cc-left {
    left: 3em;
    right: unset;
  }
  .cc-revoke.cc-right {
    right: 3em;
    left: unset;
  }
  .cc-top {
    top: 1em;
  }
  .cc-left {
    left: 1em;
  }
  .cc-right {
    right: 1em;
  }
  .cc-bottom {
    bottom: 1em;
  }
  .cc-floating > .cc-link {
    margin-bottom: 1em;
  }
  .cc-floating .cc-message {
    display: block;
    margin-bottom: 1em;
  }
  .cc-window.cc-floating .cc-compliance {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  .cc-window.cc-banner {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cc-banner.cc-top {
    left: 0;
    right: 0;
    top: 0;
  }
  .cc-banner.cc-bottom {
    left: 0;
    right: 0;
    bottom: 0;
  }
  .cc-banner .cc-message {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
    margin-right: 1em;
  }
  .cc-compliance {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .cc-floating .cc-compliance > .cc-btn {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .cc-btn + .cc-btn {
    margin-left: 0.5em;
  }
  @media print {
    .cc-revoke,
    .cc-window {
      display: none;
    }
  }
  @media screen and (max-width: 900px) {
    .cc-btn {
      white-space: normal;
    }
  }
  @media screen and (max-width: 414px) and (orientation: portrait),
    screen and (max-width: 736px) and (orientation: landscape) {
    .cc-window.cc-top {
      top: 0;
    }
    .cc-window.cc-bottom {
      bottom: 0;
    }
    .cc-window.cc-banner,
    .cc-window.cc-floating,
    .cc-window.cc-left,
    .cc-window.cc-right {
      left: 0;
      right: 0;
    }
    .cc-window.cc-banner {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .cc-window.cc-banner .cc-compliance {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
    }
    .cc-window.cc-floating {
      max-width: none;
    }
    .cc-window .cc-message {
      margin-bottom: 1em;
    }
    .cc-window.cc-banner {
      -webkit-box-align: unset;
      -ms-flex-align: unset;
      align-items: unset;
    }
    .cc-window.cc-banner .cc-message {
      margin-right: 0;
    }
  }
  .cc-floating.cc-theme-classic {
    padding: 1.2em;
    border-radius: 5px;
  }
  .cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
    text-align: center;
    display: inline;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }
  .cc-theme-classic .cc-btn {
    border-radius: 5px;
  }
  .cc-theme-classic .cc-btn:last-child {
    min-width: 140px;
  }
  .cc-floating.cc-type-info.cc-theme-classic .cc-btn {
    display: inline-block;
  }
  .cc-theme-edgeless.cc-window {
    padding: 0;
  }
  .cc-floating.cc-theme-edgeless .cc-message {
    margin: 2em;
    margin-bottom: 1.5em;
  }
  .cc-banner.cc-theme-edgeless .cc-btn {
    margin: 0;
    padding: 0.8em 1.8em;
    height: 100%;
  }
  .cc-banner.cc-theme-edgeless .cc-message {
    margin-left: 1em;
  }
  .cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
    margin-left: 0;
  }
  .notification-container-bottom-center,
  .notification-container-bottom-left,
  .notification-container-bottom-right,
  .notification-container-center,
  .notification-container-top-center,
  .notification-container-top-left,
  .notification-container-top-right {
    width: 325px;
    position: absolute;
    pointer-events: all;
  }
  .notification-container-bottom-center,
  .notification-container-center,
  .notification-container-top-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: calc(50% - 162.5px);
  }
  .notification-container-center {
    top: 20px;
    height: 100%;
    pointer-events: none;
  }
  .notification-container-center .center-inner {
    width: 325px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    pointer-events: all;
  }
  .notification-container-top-center {
    top: 20px;
  }
  .notification-container-bottom-center {
    bottom: 20px;
  }
  .notification-container-top-left {
    left: 20px;
    top: 20px;
  }
  .notification-container-top-right {
    right: 20px;
    top: 20px;
  }
  .notification-container-bottom-left {
    left: 20px;
    bottom: 20px;
  }
  .notification-container-bottom-right {
    bottom: 20px;
    right: 20px;
  }
  .notification-container-mobile-bottom,
  .notification-container-mobile-top {
    pointer-events: all;
    position: absolute;
  }
  .notification-container-mobile-top {
    right: 20px;
    left: 20px;
    top: 20px;
  }
  .notification-container-mobile-bottom {
    right: 20px;
    left: 20px;
    bottom: 20px;
    margin-bottom: -15px;
  }
  .notification-default {
    border-left: 8px solid #0562c7;
  }
  .notification-default,
  .notification-default .timer {
    background-color: #007bff;
  }
  .notification-default .timer-filler {
    background-color: #fff;
  }
  .notification-default .notification-close {
    background-color: #007bff;
  }
  .notification-success {
    border-left: 8px solid #1f8838;
  }
  .notification-success,
  .notification-success .timer {
    background-color: #28a745;
  }
  .notification-success .timer-filler {
    background-color: #fff;
  }
  .notification-success .notification-close {
    background-color: #28a745;
  }
  .notification-danger {
    border-left: 8px solid #bd1120;
  }
  .notification-danger,
  .notification-danger .timer {
    background-color: #dc3545;
  }
  .notification-danger .timer-filler {
    background-color: #fff;
  }
  .notification-danger .notification-close {
    background-color: #dc3545;
  }
  .notification-info {
    border-left: 8px solid #138b9e;
  }
  .notification-info,
  .notification-info .timer {
    background-color: #17a2b8;
  }
  .notification-info .timer-filler {
    background-color: #fff;
  }
  .notification-info .notification-close {
    background-color: #17a2b8;
  }
  .notification-warning {
    border-left: 8px solid #ce9c09;
  }
  .notification-warning,
  .notification-warning .timer {
    background-color: #eab000;
  }
  .notification-warning .timer-filler {
    background-color: #fff;
  }
  .notification-warning .notification-close {
    background-color: #eab000;
  }
  .notification-awesome {
    border-left: 8px solid #4c3fb1;
  }
  .notification-awesome,
  .notification-awesome .timer {
    background-color: #685dc3;
  }
  .notification-awesome .timer-filler {
    background-color: #fff;
  }
  .notification-awesome .notification-close {
    background-color: #685dc3;
  }
  @keyframes timer {
    0% {
      width: 100%;
    }
    to {
      width: 0;
    }
  }
  .react-notification-root {
    position: fixed;
    z-index: 9000;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
  .notification-item {
    display: flex;
    position: relative;
    border-radius: 3px;
    margin-bottom: 15px;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .notification-item .timer {
    width: 100%;
    margin-top: 10px;
  }
  .notification-item .timer,
  .notification-item .timer .timer-filler {
    height: 3px;
    border-radius: 5px;
  }
  .notification-item .notification-title {
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .notification-item .notification-message {
    color: #fff;
    max-width: calc(100% - 15px);
    font-size: 14px;
    line-height: 150%;
    word-wrap: break-word;
    margin-bottom: 0;
    margin-top: 0;
  }
  .notification-item .notification-content {
    padding: 8px 15px;
    display: inline-block;
    width: 100%;
  }
  .notification-item .notification-close {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .notification-item .notification-close:after {
    content: "✕";
    position: absolute;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 12px;
    left: 50%;
    top: 50%;
  }
  .notification-container-mobile-bottom .notification-item,
  .notification-container-mobile-bottom .notification-parent,
  .notification-container-mobile-top .notification-item,
  .notification-container-mobile-top .notification-parent {
    max-width: 100%;
    width: 100%;
  }
  .notification-container-bottom-right .notification-parent,
  .notification-container-top-right .notification-parent {
    margin-left: auto;
  }
  .notification-container-bottom-left .notification-parent,
  .notification-container-top-left .notification-parent {
    margin-right: auto;
  }
  .notification-container-mobile-bottom .notification-parent,
  .notification-container-mobile-top .notification-parent {
    margin-left: auto;
    margin-right: auto;
  }
  .overwriteOverflow {
    overflow: visible !important;
  }
  .overwriteOverflow > div {
    overflow: hidden !important;
  }
`;
