import React, { lazy, Suspense } from 'react';
import { Switch, Router } from 'react-router-dom';
import { Normalize } from 'styled-normalize';
import { ThemeProvider } from 'theme-ui';
import { createBrowserHistory } from 'history';
import { observer } from 'mobx-react';
import { Provider, rootStore } from 'models/RootStore';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import makeInspectable from 'mobx-devtools-mst';

import { Header, PublicRoute, WithTracker, Loader } from 'components';

import { MenuProvider } from 'context/MenuContext';
import { theme, pageTitles } from 'consts';
import { ScrollToTop } from 'utils';
import GlobalStyles from './GlobalStyles';

const Homepage = lazy(() => import('pages/landing/Homepage'));
const NotFound = lazy(() => import('pages/landing/NotFound'));
const Terms = lazy(() => import('pages/legal/Terms'));
const Policy = lazy(() => import('pages/legal/Policy'));
const Cookies = lazy(() => import('pages/legal/Cookies'));

const TwitterFollowersFollowingLanding = lazy(() =>
  import('pages/landing/TwitterFollowersFollowing')
);
const TwitterAccountTimelineLanding = lazy(() =>
  import('pages/landing/TwitterAccountTimeline')
);
const TwitterInfluencersLanding = lazy(() =>
  import('pages/landing/TwitterInfluencers')
);
const HistoricalTweetsLanding = lazy(() =>
  import('pages/landing/HistoricalTweets')
);

const history = createBrowserHistory();

makeInspectable(rootStore);

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider value={rootStore}>
      <MenuProvider>
        <Router history={history}>
          <Normalize />
          <GlobalStyles />
          <ReactNotification />
          <Header history={history} />
          <ScrollToTop />
          <Suspense fallback={<Loader />}>
            <Switch>
              <PublicRoute
                exact
                path="/"
                component={WithTracker(Homepage, {
                  pageTitle: pageTitles.landing,
                })}
              />
              <PublicRoute
                exact
                path="/terms-and-conditions"
                component={WithTracker(Terms, {
                  pageTitle: pageTitles.terms,
                })}
              />
              <PublicRoute
                exact
                path="/privacy-policy"
                component={WithTracker(Policy, {
                  pageTitle: pageTitles.privacy,
                })}
              />
              <PublicRoute
                exact
                path="/cookies"
                component={WithTracker(Cookies, {
                  pageTitle: pageTitles.cookies,
                })}
              />
              <PublicRoute
                path="/download-twitter-followers"
                component={WithTracker(TwitterFollowersFollowingLanding, {
                  pageTitle: pageTitles.followersFollowings,
                })}
              />
              <PublicRoute
                path="/download-tweets"
                component={WithTracker(HistoricalTweetsLanding, {
                  pageTitle: pageTitles.exportTweets,
                })}
              />
              <PublicRoute
                path="/download-twitter-account-timeline"
                component={WithTracker(TwitterAccountTimelineLanding, {
                  pageTitle: pageTitles.exportAccount,
                })}
              />
              <PublicRoute
                path="/twitter-influencers"
                component={WithTracker(TwitterInfluencersLanding, {
                  pageTitle: pageTitles.bioSearch,
                })}
              />
              <PublicRoute
                component={WithTracker(NotFound, {
                  pageTitle: pageTitles.notFound,
                })}
              />
            </Switch>
          </Suspense>
        </Router>
      </MenuProvider>
    </Provider>
  </ThemeProvider>
);

export default observer(App);
