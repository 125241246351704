export const refactorThese = {
  breakPointMobile: '600px',
  breakPointTablet: '768px',
  breakPointDesktop: '940px',
  breakPointDesktopLarge: '1200px',
  light: '300',
  normal: '400',
  bold: '600',
  iOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
};

export const theme = {
  breakpoints: ['40em', '52em', '64em', '76em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: '"Open Sans", sans-serif',
    heading: '"Fira Sans", sans-serif',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 700,
    medium: 500,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  shadows: {
    card: '0 4px 4px -4px rgba(0, 0, 0, 0.125)',
    largeArea: '0px 2px 54px rgba(0, 0, 0, 0.05)',
  },
  colors: {
    text: '#2C293C',
    background: '#f9f9f9',
    primary: '#423E7D',
    secondary: '#B14DFF',
    white: '#fff',
    black: '#2C293C',
    purple: ['#6C63FF', '#9E98FF', '#282548', '#211F3D'],
    yellow: '#FFE24D',
    pink: '#FF4D9C',
    teal: '#45D8EF',
    grey: '#444444',
    green: '#008F5C',
    electricGreen: '#00FFA3',
    orange: '#F87A26',
    realBlue: '#1FA2FF',
    // WIP:
    // Below colors should NOT be used
    // In the new UI
    blue: '#4f4b95',
    red: '#d00022',
    darkRed: '#ab001b',
    modes: {
      dark: {
        text: '#fff',
        background: '#423E7D',
        primary: '#0cf',
        grey: '#fff',
        blue: '#00FFA3',
      },
    },
  },
  buttons: {
    primary: {
      bg: 'electricGreen',
    },
    main: {
      background: 'linear-gradient(270deg,#423e7d -63.96%,#b14dff 144.16%)',
      outline: 'none',
      cursor: 'pointer',
    },
    purple: {
      bg: 'pink',
      outline: 'none',
      cursor: 'pointer',
    },
    electricGreen: {
      bg: 'electricGreen',
      outline: 'none',
      cursor: 'pointer',
    },
    green: {
      bg: '#1bc963',
      fontWeight: 'heading',
      outline: 'none',
    },
    gray: {
      bg: '#ccc',
      fontWeight: 'heading',
      outline: 'none',
      cursor: 'pointer',
    },
    red: {
      bg: 'red',
      fontWeight: 'heading',
      outline: 'none',
      cursor: 'pointer',
    },
    sample: {
      py: '12px',
      px: '16px',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: 'medium',
      cursor: 'pointer',
      outline: 'none',
      boxShadow: '0px 15px 25px -15px #38356d',
      background: 'transparent',
      color: 'black',
      border: '1px solid #1FA2FF',
      transition: '.3s',
      '&:hover': {
        background: '#eff6ff',
      },
    },
  },
  text: {
    'header-span': {
      fontSize: [2, 3],
      color: 'white',
    },
    'header-h1': {
      fontSize: [4, '36px'],
      color: 'white',
    },
    'header-h2': {
      fontSize: [2, 3],
      color: 'white',
    },
  },
  variants: {
    card: {
      p: [3, 4],
      bg: 'white',
      boxShadow: 'card',
      borderRadius: 8,
    },
    transparent: {
      p: [3, 4],
      borderRadius: 8,
      background: 'rgba(40, 37, 72, 0.2)',
    },
  },
  radii: {
    small: '4px',
    medium: '8px',
  },
};
