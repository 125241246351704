import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ title, keywords, description, robots, canonical }) => (
  <Helmet>
    <title>{title} | ExportData.io</title>
    {keywords && <meta name="keywords" content={keywords} />}
    {description && <meta name="description" content={description} />}
    {canonical && <link rel="canonical" href={canonical} />}

    <meta
      name="robots"
      content={`${robots !== undefined ? `${robots}, ` : ''}index, follow`}
    />
  </Helmet>
);

export default MetaTags;
