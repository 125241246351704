import React, { useReducer, useEffect } from 'react';

const initialState = { open: false };

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_MENU_STATE':
      return { ...state, open: !state.open };
    case 'CLOSE_MENU_STATE':
      return { ...state, open: false };
    default:
      return state;
  }
};

const MenuContext = React.createContext();

const MenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  useEffect(() => {
    if (state.open) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
  }, [state]);

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export { MenuContext, MenuProvider };
