/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

const Filter = types
  .model('Filter', {
    name: types.string,
    checked: types.boolean
  })
  .actions(self => ({
    toggle() {
      self.checked = !self.checked;
    }
  }));

export default Filter;
