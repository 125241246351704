import React, { Component } from 'react';
import styled from 'styled-components';

import { Inner } from 'components';
import Slider from 'react-rangeslider';
import { theme, refactorThese } from 'consts';

const SliderCss = styled.div`
  .rangeslider {
    margin: 20px 0;
    position: relative;
    background: rgb(250, 217, 97);
    background: linear-gradient(
      90deg,
      rgba(250, 217, 97, 1) 0%,
      rgba(247, 107, 28, 1) 100%
    );
    -ms-touch-action: none;
    touch-action: none;
  }
  .rangeslider,
  .rangeslider__fill {
    display: block;
  }
  .rangeslider .rangeslider__handle {
    background: rgb(250, 217, 97);
    background: linear-gradient(
      180deg,
      rgba(250, 217, 97, 1) 0%,
      rgba(247, 107, 28, 1) 100%
    );
    border: 3px solid #fff;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    outline: none;
    z-index: 1;
  }
  .rangeslider .rangeslider__handle .rangeslider__active {
    opacity: 1;
  }
  .rangeslider-horizontal {
    height: 14px;
    border-radius: 10px;
  }
  .rangeslider__fill {
    height: 100%;
    border-radius: 10px;
    top: 0;
  }
  .rangeslider__handle {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .rangeslider__handle-tooltip {
    top: -55px;
  }
  .rangeslider__handle-tooltip:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(0, 0, 0, 0.8);
    left: 50%;
    bottom: -8px;
    transform: translate3d(-50%, 0, 0);
  }
  .rangeslider__labels {
    position: relative;
    top: -80px;
  }
  .rangeslider__labels .rangeslider__label-item {
    position: absolute;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    color: #c3c3c3;
    white-space: nowrap;
    transform: translate3d(-50%, 0, 0);
  }

  .rangeslider__handle-label {
    padding: 10px 20px 10px 45px;
    text-align: center;
    position: absolute;
    background: rgb(250, 217, 97);
    background: linear-gradient(
      180deg,
      rgba(250, 217, 97, 1) 0%,
      rgba(247, 107, 28, 1) 100%
    );
    font-weight: bold;
    font-size: 20px;
    transition: all 100ms ease-in;
    border-radius: 30px;
    display: inline-block;
    color: white;
    left: 50%;
    top: -58px;
    white-space: nowrap;
    transform: translate3d(-50%, 0, 0);
  }

  .rangeslider__handle-label:after {
    content: ' ';
    position: absolute;
    width: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #f76b1c;
    left: 50%;
    bottom: -8px;
    transform: translate3d(-50%, 0, 0);
  }

  .rangeslider__handle-label:before {
    content: 'c';
    position: absolute;
    top: 6px;
    left: 7px;
    width: 30px;
    height: 30px;
    border: 1px solid #ffc462;
    background: #f76b1c;
    border-radius: 50%;
    box-sizing: border-box;
    background: rgb(254, 134, 67);
    background: linear-gradient(
      0deg,
      rgba(254, 134, 67, 1) 0%,
      rgba(247, 107, 28, 1) 100%
    );
  }
`;

const SliderDashedWrapper = styled.div`
  border: 2px dashed ${theme.colors.orange};
  border-radius: 10px;
  padding: 0% 8% 7%;
`;

const SliderWrapper = styled.div`
  margin: 80px 0 0;
`;

const Title = styled.h2`
  border: 2px dashed ${theme.colors.orange};
  color: ${theme.colors.orange};
  display: initial;
  padding: 20px;
  background: #fff;
  border-radius: 50px;
  position: relative;
  top: -17px;
  font-size: 1em;

  @media (min-width: ${refactorThese.breakPointTablet}) {
    padding: 20px 40px;
    font-size: 1.25em;
  }
`;

const Paragraph = styled.p`
  margin: 10% 0 35%;
  font-weight: ${refactorThese.light}
  color: ${theme.colors.grey}
  font-style: italic;
  line-height: 1.5;

  @media (min-width: ${refactorThese.breakPointTablet}) {
    font-size: 1.25em;
    margin: 7% 0 15%;
  }

  span {
    background: ${theme.colors.orange};
    background: #F87A26;
    color: #fff;
    font-weight: ${refactorThese.bold}
    font-style: normal;
    border-radius: 50%;
    line-height: 1.2;
    width: 30px;
    height: 30px;
    display: inline-block;
    position: relative;
    top: -1px;
    margin-right: 2px;
  }
`;

const Pricing = styled.div`
  color: ${theme.colors.grey}
  text-align: right;
  font-size: 1.5em;
  font-weight: ${refactorThese.light}
  margin: 40px 0 0;

  span {
    font-size: 1em;
  }
  strong {
    font-size: 1.3em;
    font-weight: ${refactorThese.bold}
  }
`;

class PricingSlider extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      horizontal: 50,
      tooltipValue: '20,000'
    };
  }

  componentDidMount() {
    const { horizontal } = this.state;
    this.calculatePrice(horizontal);
  }

  calculatePrice = value => {
    const modValue = value;
    let totalPrice;

    /**
     * TODO: Get it from server instead of hardcoding
     */

    switch (modValue) {
      case 0:
        totalPrice = 1 * 299;
        break;
      case 50:
        totalPrice = 1 * 499;
        break;
      case 100:
        totalPrice = 1 * 799;
        break;
      case 150:
        totalPrice = 1 * 1299;
        break;
      default:
        totalPrice = 1 * 10299;
        break;
    }

    this.setState({
      price: totalPrice / 100
    });
  };

  tooltipValue = value => {
    let modValue = value;

    switch (modValue) {
      case 0:
        modValue = 5;
        break;
      case 50:
        modValue = 20;
        break;
      case 100:
        modValue = 50;
        break;
      case 150:
        modValue = 100;
        break;
      default:
        modValue = 1000;
        break;
    }

    const ending = ',000';
    const finalValue =
      modValue === 1000 ? '1,000,000' : modValue.toString() + ending;

    return finalValue;
  };

  handleChangeHorizontal = value => {
    const { sliderValue, innerPage } = this.props;
    const tooltipValue = this.tooltipValue(value);

    if (innerPage) {
      sliderValue(tooltipValue);
    }
    this.calculatePrice(value);
    this.setState({
      horizontal: value,
      tooltipValue
    });
  };

  render() {
    const { innerPage } = this.props;
    const { horizontal, tooltipValue, price } = this.state;
    const horizontalLabels = {
      0: '5k',
      50: '20k',
      100: '50k',
      150: '100K',
      200: '1M'
    };

    return (
      <SliderCss>
        <Inner display="block">
          {innerPage ? (
            <SliderDashedWrapper>
              <Title>How many do you need?</Title>
              <Paragraph>
                <span>c</span> 1 credit = 1 potential lead. Buy in advance,
                you&apos;ll use only as many as you need a the time.
              </Paragraph>
              <Slider
                min={0}
                max={200}
                step={50}
                tooltip={false}
                handleLabel={tooltipValue}
                value={horizontal}
                labels={horizontalLabels}
                onChange={this.handleChangeHorizontal}
              />
              <Pricing>
                <span>
                  Only: <strong>${price}</strong>
                </span>
              </Pricing>
            </SliderDashedWrapper>
          ) : (
            <SliderWrapper>
              <Slider
                min={0}
                max={200}
                step={50}
                tooltip={false}
                handleLabel={tooltipValue}
                value={horizontal}
                labels={horizontalLabels}
                onChange={this.handleChangeHorizontal}
              />
              <Pricing>
                <span>
                  Only: <strong>${price}</strong>
                </span>
              </Pricing>
            </SliderWrapper>
          )}
        </Inner>
      </SliderCss>
    );
  }
}

export default PricingSlider;
