import React from 'react';
import blobV1 from 'assets/blob-1.svg';

import styled from 'styled-components';

const Wrapper = styled.div`
  background: url(${props => (props.url ? props.url : blobV1)});
  background-size: contain;
  background-repeat: no-repeat;
  width: 270px;
  height: 230px;
  margin: 0 auto;
`;

const Blob = ({ children, url }) => {
  return <Wrapper url={url}>{children}</Wrapper>;
};

export default Blob;
