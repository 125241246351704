import styled from 'styled-components';

import { theme, refactorThese } from 'consts';

const NavWrapper = styled.nav`
  left: 0;
  top: 0;
  z-index: 2;

  @media (min-width: ${refactorThese.breakPointDesktop}) {
    position: relative;
    height: auto;
    width: auto;
    top: -5px;
  }

  div {
    position: relative
    display: block;
    background: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    z-index: 3;
    cursor: pointer;
    border-radius: 50%;
    outline none;
    cursor: pointer;

    @media (min-width: ${refactorThese.breakPointDesktop}) {
      display: none;
    }

    span {
      border-bottom: 2px solid #fff;
      position: absolute;
      top: 14px;
      left: 10px;
      width: 20px;
      transition: all 0.3s;
      border-radius: 2px;

      &:before, &:after  {
        width: 100%;
        content: '';
        height: 2px;
        background: #fff;
        position: absolute;
        left: 0;
        top: 5px;
        transition: all 0.1s;
        border-radius: 2px;

        .menu-open & {
          left: -5px;
        }
      }
      &:after {
        top: 10px;
        .menu-open & {
          left: 0;
        }
      }
    }
  }

  .mobile-menu-logo {
    position: fixed;
    left: 32px;
    top: 30px;
    opacity: 0;
    transition: all 0.3s;

    .menu-open & {
      opacity: 1;
      user-select:none;
    }

    @media (min-width: ${refactorThese.breakPointDesktop}) {
      visibility:hidden !important;
    }
  }

  ul {
    position: fixed;
    top: 0;
    right: -100vw;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
    z-index: 2;
    transition: all 0.2s;
    padding: 120px 0;
    transform-origin: right;


    .menu-open & {
      top: 0;
      right: 0;
      height: 100vh;
      background: #403b77;
      user-select:none;

      @media (min-width: ${refactorThese.breakPointDesktop}) {
        height: 0;
        background: none;
      }
    }

    @media (min-width: ${refactorThese.breakPointDesktop}) {
      position: relative;
      top: 10px;
      display: flex !important;
      padding: 0;
      right: 0;

    }

    li {
      a {
        position: relative;
        display: block;
        padding: 10px 15px;
        text-decoration: none;
        background: #403c77;
        color: #fff;
        border-bottom: 1px solid #494488;
        font-size: 15px;

        &.products-link {
          display: none;
          @media (min-width: ${refactorThese.breakPointDesktop}) {
            display: block;
          }
        }

        &:after {
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          content: '';
          border-bottom: solid 1px ${theme.colors.white};
          transform: scaleX(0);
          transition: transform 250ms ease-in-out;
        }

        &:hover {
          &:after {
            transform: scaleX(1);
          }
        }

        @media (min-width: ${refactorThese.breakPointDesktop}) {
          float: left;
          background: none;
          border-bottom: none;
          color: #fff;
          &.active {
            border-bottom: 1px solid ${theme.colors.electricGreen};
          }
        }

      }
    }
  }
`;

export default NavWrapper;
