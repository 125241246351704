import React from 'react';
import PropTypes from 'prop-types';

import CheckBoxWrapper from './Styles';

const propTypes = {
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool
};

const defaultProps = {
  isChecked: true
};

const CheckBox = ({ label, isChecked, onChange }) => (
  <CheckBoxWrapper>
    <label htmlFor={`ed_${label}`}>
      <input
        type="checkbox"
        id={`ed_${label}`}
        checked={isChecked}
        onChange={onChange}
      />
      {label}
    </label>
  </CheckBoxWrapper>
);

CheckBox.propTypes = propTypes;
CheckBox.defaultProps = defaultProps;

export default CheckBox;
