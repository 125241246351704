import React from 'react';
import PropTypes from 'prop-types';
import { Card, Flex, Box, Heading, Text } from 'rebass';
import { useColorMode } from 'theme-ui';
import { Link } from 'react-router-dom';
import { ArrowRightCircle } from 'react-feather';

import blobGreen from 'assets/blob-1.svg';
import blobYellow from 'assets/blob-2.svg';
import blobBlue from 'assets/blob-3.svg';
import blobPink from 'assets/blob-4.svg';

const propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  blobColor: PropTypes.string.isRequired,
};

const ProductInfo = ({ icon, title, text, link, blobColor, external }) => {
  const [theme] = useColorMode();

  let blob;
  let linkColor;

  switch (blobColor) {
    case 'yellow':
      blob = blobYellow;
      linkColor = 'orange';
      break;

    case 'pink':
      blob = blobPink;
      linkColor = 'pink';
      break;

    case 'blue':
      blob = blobBlue;
      linkColor = 'purple.0';
      break;

    default:
      blob = blobGreen;
      linkColor = 'teal';
      break;
  }

  const renderLinkContent = () => {
    return (
      <Flex
        sx={{
          color: linkColor,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ArrowRightCircle />
        <Text mx="1" as="strong">
          Tell Me More
        </Text>
      </Flex>
    );
  };

  return (
    <Card
      width={['100%', '46%', '32%']}
      m={[0, 2, 3]}
      mb={4}
      sx={{
        position: 'relative',
        boxShadow: 'largeArea',
        a: {
          textDecoration: 'none',
          width: '100%',
          display: 'block',
          p: 3,
          borderRadius: 'small',
          transition: 'all .3s',
          backgroundColor: linkColor,
          color: 'white',
          strong: {
            color: 'white',
          },
          svg: {
            stroke: 'white',
          },
        },
      }}
      variant={theme === 'dark' ? 'transparent' : 'card'}
    >
      <Box
        sx={{
          position: 'relative',
          img: {
            width: '100%',
            '&:nth-of-type(1)': {
              width: '59%',
              position: 'absolute',
              borderRadius: '50%',
              left: '50%',
              marginLeft: '-31%',
              top: '20%',
            },
          },
        }}
      >
        <img src={icon} alt={title} />
        <img src={blob} alt="Blob" />
      </Box>
      <Heading
        as="h3"
        sx={{
          my: 3,
          fontSize: 3,
        }}
      >
        {title}
      </Heading>
      <Text sx={{ my: 4 }}>{text}</Text>
      {external ? (
        <a href={link}>{renderLinkContent()}</a>
      ) : (
        <Link to={link}>{renderLinkContent()}</Link>
      )}
    </Card>
  );
};

ProductInfo.propTypes = propTypes;

export default ProductInfo;
