import { v4 as uuid } from 'uuid';

export function trackConversion(cents) {
  window.dataLayer.push({
    event: 'Conversion',
    transactionId: uuid(),
    transactionAffiliation: 'ExportData.io',
    transactionTax: 0,
    transactionShipping: 0,
    transactionTotal: cents / 100,
    transactionProducts: [
      {
        sku: 'v1',
        name: 'Account Export',
        category: 'Account',
        price: cents / 100,
        quantity: 1,
      },
    ],
    conversionValue: cents / 100,
    conversionCurrency: 'USD',
  });
}

export function trackPageview(pagePath, options = {}) {
  window.dataLayer.push({
    event: 'Pageview',
    pagePath,
    ...options,
  });
}
