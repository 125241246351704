const headerData = {
  homepage: {
    span: 'Twitter Data Export & Analysis Tool',
    h1: 'Export Twitter Historical Data',
    h2: 'Analyze and export Twitter followers, followings, account timeline, historical tweets and more. Download your analysis in PDF or CSV formats',
    image: 'viral-tweet',
  },
  followersFollowings: {
    span: 'Deep insights',
    h1: 'Download Twitter Followers & Followings',
    h2: 'Generate new leads, analyse Twitter accounts and build tailored Twitter audiences',
    image: 'product',
  },
  tweets: {
    span: 'Analytics',
    h1: 'Download Historical Tweets by Hashtag or Keyword',
    h2: 'Access the full tweets archive. Search tweets using specific hashtags or keywords and get reports with stats and exclusive metrics based on preprietary technology, get reports in PDF or CSV formats.',
    image: 'tweetstorm',
    dashboardRedirectURL: '/export/tweets',
  },
  timeline: {
    span: 'Measure Engagement',
    h1: 'Export Twitter Account Timeline',
    h2: 'Export all user tweets of any public Twitter account timeline and get comprehensive analysis.',
    image: 'report',
  },
  bioSearch: {
    span: 'Search',
    h1: 'Search Twitter Bios and Find Twitter Influencers',
    h2: 'Fedica\'s profile search is a great way to find the right Twitter influencers, discover the target audience, or find people to follow.',
    image: 'bio',
    dashboardRedirectURL: '/export/twitter-influencers',
  },
};

export default headerData;
