/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

const Payment = types
  .model({
    intent: types.frozen({
      response: {
        client_secret: types.string,
        price: types.number,
        discount: types.number
      }
    }),
    isValidIntent: types.boolean,
    showPaymentForm: types.boolean,
    successData: types.maybe(types.frozen({}))
  })
  .actions(self => ({
    setIntent(obj) {
      self.intent = obj;
    },
    setIsValidIntent(bool) {
      self.isValidIntent = bool;
    },
    setShowPaymentForm(bool) {
      self.showPaymentForm = bool;
    },
    setSuccessData(obj) {
      self.successData = obj;
    }
  }));

export default Payment;
