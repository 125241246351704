/* eslint-disable react/no-danger */
import React from 'react';
import { useColorMode } from 'theme-ui';
import { Flex } from 'rebass';
import { v4 as uuid } from 'uuid';

import { Inner, Card, Icon } from 'components';

const UseCases = ({ data }) => {
  const [theme] = useColorMode();
  return (
    <Flex backgroundColor={theme === 'dark' ? '#333061' : '#fff'} pt="5">
      <Inner maxWidth={1300}>
        {data.map((caseStudy) => (
          <Card
            key={uuid()}
            icon={<Icon iconName={caseStudy.iconName} />}
            title={caseStudy.title}
            fullWidth
            useCases
            reverse={caseStudy.reverse}
          >
            <p dangerouslySetInnerHTML={{ __html: caseStudy.text }} />
          </Card>
        ))}
      </Inner>
    </Flex>
  );
};

export default UseCases;
