import React, { useState } from 'react';
import { Flex, Text } from 'rebass';
import { useColorMode } from 'theme-ui';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import { CheckCircle } from 'react-feather';
import { v4 as uuid } from 'uuid';

import { metaData as twitterFollowersFollowingFields } from 'consts/pages/TwitterFollowersFollowing';
import { metaData as timelineFields } from 'consts/pages/TwitterAccountTimeline';

const followersMeta = twitterFollowersFollowingFields.map(
  (field) => field.title
);
const followingMeta = [...followersMeta];
const timelineMeta = timelineFields.map((field) => field.title);

const MetaDetail = ({ children }) => {
  const [theme] = useColorMode();
  return (
    <Flex
      my="2"
      mr="4"
      key={uuid()}
      alignItems="center"
      sx={{
        color: theme === 'dark' ? 'white' : 'black',
        svg: {
          mr: 2,
        },
      }}
    >
      <CheckCircle color={theme === 'dark' ? '#00FFA3' : '#19d200'} />
      <Text
        sx={{
          fontSize: '1',
        }}
      >
        {children}
      </Text>
    </Flex>
  );
};

const Accordion = ({
  accordionTitle,
  accordionContent,
  initialState = false,
}) => {
  const [open, setOpen] = useState(initialState);
  const [theme] = useColorMode();

  return (
    <Flex flexDirection="column" sx={{ userSelect: 'none' }}>
      <Flex
        onClick={() => setOpen(!open)}
        onKeyDown={() => setOpen(!open)}
        alignItems="center"
        justifyContent="space-between"
        width={1}
        sx={{
          position: 'relative',
          px: 3,
          py: 2,
          fontWeight: 'bold',
          color: theme === 'dark' ? 'white' : 'black',
          borderRadius: 'medium',
          background:
            theme === 'dark'
              ? 'linear-gradient(180deg, #5B559F 0%, #383463 100%)'
              : 'linear-gradient(180deg, #ffffff 0%, #e8e8e8 100%)',
          cursor: 'pointer',
          '&:after': {
            content: `''`,
            position: 'absolute',
            top: '10px',
            left: '0',
            width: '3px',
            height: '30px',
            background:
              theme === 'dark'
                ? 'linear-gradient(180deg, #7bfec7 13.16%, #ffd500 100%)'
                : 'grey',
            borderRadius: '2px',
          },
        }}
      >
        <span>{accordionTitle}</span>
        {open ? (
          <ToggleOnIcon fontSize="large" />
        ) : (
          <ToggleOffIcon fontSize="large" color="action" />
        )}
      </Flex>

      {open && (
        <Flex
          flexWrap="wrap"
          sx={{
            p: 3,
            mx: 2,
            color: theme === 'dark' ? 'white' : 'black',
            backgroundColor: theme === 'dark' ? 'purple.2' : 'white',
            borderBottom:
              theme === 'dark' ? '1px solid #252340' : '1px solid #f7f7f7',
          }}
        >
          {accordionContent === 'Followers' &&
            followersMeta.map((i) => <MetaDetail key={uuid()}>{i}</MetaDetail>)}

          {accordionContent === 'Followings' &&
            followingMeta.map((i) => <MetaDetail key={uuid()}>{i}</MetaDetail>)}

          {accordionContent === 'Timeline' &&
            timelineMeta.map((i) => <MetaDetail key={uuid()}>{i}</MetaDetail>)}
        </Flex>
      )}
    </Flex>
  );
};
export default Accordion;
