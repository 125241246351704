import { useContext, createContext } from 'react';
import { types, onPatch } from 'mobx-state-tree';
import { store as notificationsStore } from 'react-notifications-component';
import { values } from 'mobx';

import Search from './Search';
import Payment from './Payment';
import Notification from './Notification';
import Filter from './Filter';

const RootModel = types
  .model({
    search: Search,
    payment: Payment,
    notification: Notification,
    filters: types.array(Filter),
  })
  .views((self) => ({
    get filtersSelectedCount() {
      return values(self.filters).filter((filter) => filter.checked).length;
    },
    get filtersSelected() {
      return self.filters
        .filter((f) => f.checked === true)
        .map((i) => i.name.toLowerCase());
    },
  }));

export const rootStore = RootModel.create({
  search: {
    accountName: '',
    validation: {
      message: '',
      valid: false,
      type: '',
    },
    gettingData: false,
    setTwitterUserData: {},
    numberOfTweetsToExport: 0,
  },
  payment: {
    intent: {
      response: {
        client_secret: '',
        price: '',
        discount: '',
      },
    },
    isValidIntent: false,
    showPaymentForm: false,
  },
  notification: {},
  filters: [
    {
      name: 'Followers',
      checked: true,
    },
    {
      name: 'Followings',
      checked: true,
    },
    {
      name: 'Timeline',
      checked: true,
    },
  ],
});

// onSnapshot(rootStore, snapshot => console.log('Snapshot: ', snapshot));
// onPatch(rootStore.search, patch => console.log('Patch', patch));

onPatch(rootStore.notification, (patch) => {
  if (
    patch.path === '/message' &&
    patch.value.message !== '' &&
    patch.value.message !== undefined
  ) {
    notificationsStore.addNotification({
      title: patch.value.title,
      message: patch.value.message,
      type: patch.value.type || 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['fadeInLeft', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        showIcon: true,
        duration: patch.value.autoDismiss ? 3000 : 120000,
        onScreen: !!patch.value.autoDismiss,
        pauseOnHover: true,
      },
    });
  }
});

onPatch(rootStore.search, (patch) => {
  if (
    patch.path === '/validation' &&
    patch.value.message !== '' &&
    patch.value.message !== undefined
  ) {
    notificationsStore.addNotification({
      title: 'Whoops!',
      message: patch.value.message,
      type: patch.value.type || 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['fadeInLeft', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        showIcon: true,
        duration: 3000,
        onScreen: true,
        pauseOnHover: true,
      },
    });
  }
});

const RootStoreContext = createContext(null);

export const { Provider } = RootStoreContext;

export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
