import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Text } from 'rebass';

import { Inner } from 'components';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  headingType: PropTypes.string,
};

const defaultProps = {
  id: '',
  children: '',
  headingType: 'h2',
};

const childrenRender = (children) => {
  return React.Children.map(children, (child, i) => {
    return i === 0 ? (
      <Text
        as="p"
        sx={{
          fontSize: 3,
          mt: 3,
        }}
      >
        {child}
      </Text>
    ) : (
      child
    );
  });
};

const SectionHeader = ({ children, title, id, headingType }) => {
  return (
    <Inner display="block" id={id}>
      <Box
        sx={{
          my: 4,
        }}
      >
        <Heading
          as={headingType}
          sx={{
            fontSize: [4, 5],
            lineHeight: 'body',
          }}
        >
          {title}
        </Heading>
        {childrenRender(children)}
      </Box>
    </Inner>
  );
};

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
