import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { theme } from 'consts';

const Button = styled.div`

@media (min-width: ${theme.breakpoints[1]}) {
  display: none;
}

  position: relative;
  background: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline none;
  cursor: pointer;

  span {
    border-bottom: 2px solid #fff;
    position: absolute;
    top: 14px;
    left: 10px;
    width: 20px;
    transition: all 0.3s;
    border-radius: 2px;

    &:before, &:after  {
      width: 100%;
      content: '';
      height: 2px;
      background: #fff;
      position: absolute;
      left: 0;
      top: 5px;
      transition: all 0.1s;
      border-radius: 2px;

      ${ifProp(
        'isOpen',
        css`
          left: -5px;
        `
      )}
    }
    &:after {
      top: 10px;
      ${ifProp(
        'isOpen',
        css`
          left: 0;
        `
      )}
    }
  }
`;

const MobileMenuButton = ({ isOpen, onClick }) => {
  return (
    <Button isOpen={isOpen} onClick={onClick}>
      <span />
    </Button>
  );
};

export default MobileMenuButton;
