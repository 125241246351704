import styled from 'styled-components';
import { theme } from 'consts';

const HeaderContainer = styled.div`
  position: relative;
  background: ${theme.colors.blue};
  height: ${props => (props.minimal ? '110px' : '615px')};
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODQ1IiBoZWlnaHQ9IjU4MSIgdmlld0JveD0iMCAwIDg0NSA1ODEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMjEyLjAzIC01MjguNTNDMTM1Ny43NSAtNDM2LjYxNCAxNDcyLjM2IC0yOTguNDExIDE0OTYuMTcgLTEyNy43NzlDMTUyMC4xOSA0NC4zMjI3IDE0NDguNzMgMjEwLjMyMiAxMzMzLjI0IDM0MC4xNkMxMjEwLjg1IDQ3Ny43NTEgMTA1MC4xNiA1OTAuNjkgODY2LjMwNyA1ODAuMzQxQzY3NS4zNjMgNTY5LjU5MyA1MTEuNzg1IDQ0Ny4yNzggNDA1Ljk4IDI4Ny45NjZDMjk5LjI4OSAxMjcuMzE4IDI1MC4zNTMgLTcxLjI0OTYgMzEyLjI2NSAtMjUzLjg5QzM3My41NTMgLTQzNC42ODkgNTMxLjU0MyAtNTY1LjM0NSA3MTQuMTMxIC02MjEuMDc3Qzg4My45OTYgLTY3Mi45MjYgMTA2MS44MSAtNjIzLjI4MyAxMjEyLjAzIC01MjguNTNaIiBmaWxsPSIjRkY0RDlDIi8+CjxwYXRoIGQ9Ik0xMDU2LjQ5IC0zMTYuNjE3QzExNjkuMTcgLTE5Mi44MDMgMTE0My4yMiAtMS40NTMwNiAxMDk1Ljg2IDE1OS4xMTlDMTA1Mi42OSAzMDUuNTIgOTYzLjcxNyA0NDkuODg5IDgxNy41MDMgNDkzLjdDNjg2Ljg1NSA1MzIuODQ2IDU4Mi41NDMgNDAxLjE5OCA0NTUuNzY1IDM1MC45MTRDMzA0LjU3IDI5MC45NDUgOTAuNTMxNiAzMjguMTM0IDI0Ljg3NiAxNzkuMzIxQy00NS41MzAzIDE5LjczOSA0NS41MTc3IC0xNzQuMzYyIDE3MC4yMDQgLTI5Ni4zMzJDMjgwLjQ4OCAtNDA0LjIxMiA0NDYuOTYyIC0zODAuOTA5IDYwMS4xOTYgLTM4NC40NEM3NjIuNTg4IC0zODguMTMzIDk0Ny44MzggLTQzNi4wMTEgMTA1Ni40OSAtMzE2LjYxN1oiIGZpbGw9IiM2ODQyRkYiIGZpbGwtb3BhY2l0eT0iMC42OSIvPgo8L3N2Zz4K');
  background-position: top right;
  background-repeat: no-repeat;
`;

export default HeaderContainer;
