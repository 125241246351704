export default [
  {
    heading: 'Analyze Complete List of Followers',
    text:
      'We analyze the complete Twitter followers and followings of any public account.',
    iconName: 'followers'
  },
  {
    heading: 'Demographic insights',
    text:
      'Learn where their audience lives, what\'s their gender, occupation or age. Identify their interests.',
    iconName: 'leads'
  },
  {
    heading: 'Analyze Large or Small Accounts',
    text:
      'Whether the account has one, or ten million followers, we are ready to analyze it. Go ahead and try it!',
    iconName: 'automatically'
  },
  {
    heading: 'Analyze Any Twitter Account',
    text:
      'Analyze your, or any other account. Gain critical insights about any competitor, influencer or brand.',
    iconName: 'report'
  },
  {
    heading: 'PDF or CSV reports',
    text:
      'Download the analyzed data and charts in PDF, PNG or CSV files.',
    iconName: 'download'
  }
];
