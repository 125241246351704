import React from 'react';
import { Route } from 'react-router-dom';

import { Footer } from 'components';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    render={(props) => (
      <>
        <Component {...props} {...rest} />
        <Footer />
      </>
    )}
  />
);

export default PublicRoute;
