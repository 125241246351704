import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  height: 30px;
  padding: 0 5%;
  position: relative;
  top: -30px;
`;

const Button = styled.button`
  float: right;
  border: none;
  border-radius: 50px;
  padding: 6px 20px 6px 6px;
  color: #fff;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  background: rgb(250, 217, 97);
  background: linear-gradient(
    180deg,
    rgba(250, 217, 97, 1) 0%,
    rgba(247, 107, 28, 1) 100%
  );
`;

const CreditIcon = styled.span`
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 4px 0 0;
  margin-right: 10px;
  border: 1px solid #ffc462;
  background: #f76b1c;
  border-radius: 50%;
  box-sizing: border-box;
  background: rgb(254, 134, 67);
  background: linear-gradient(
    0deg,
    rgba(254, 134, 67, 1) 0%,
    rgba(247, 107, 28, 1) 100%
  );
`;

const CreditsButton = ({ credits }) => {
  return (
    <ButtonWrapper>
      <Button>
        <CreditIcon>c</CreditIcon>
        {credits === 0 ? 'Buy Credits' : credits}
      </Button>
    </ButtonWrapper>
  );
};

export default CreditsButton;
