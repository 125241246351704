/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

const Search = types
  .model({
    accountName: types.string,
    validation: types.frozen({
      message: types.string,
      valid: types.boolean,
      type: types.string,
    }),
    gettingData: types.boolean,
    twitterUserData: types.frozen({}),
    exportTweetsData: types.frozen({}),
    exportableTrendsLocations: types.frozen([]),
    numberOfTweetsToExport: types.number,
  })
  .actions((self) => ({
    setSearchName(name) {
      self.accountName = name;
    },
    setValidation(obj) {
      self.validation = obj;
    },
    setGettingData(bool) {
      self.gettingData = bool;
    },
    setTwitterUserData(obj) {
      self.twitterUserData = obj;
    },
    setExportTweetsData(obj) {
      self.exportTweetsData = obj;
    },
    setExportableTrendsLocations(obj) {
      self.exportableTrendsLocations = obj;
    },
    setNumberOfTweetsToExport(number) {
      self.numberOfTweetsToExport = number;
    },
  }));

export default Search;
